<template>
  <div class="ApprovalTag">
    <!-- 待办任务 -->
    <van-nav-bar
      :title="'待办任务'"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-search
        v-model="searchVal"
        shape="round"
        :placeholder="'搜索'"
        @search="onSearch"
      />
      <van-tabs
        v-model="activeName"
        color="#2B8DF0"
        title-active-color="#2B8DF0"
        @change="changeAct"
      >
        <van-tab :title="'待提交'" name="s0"></van-tab>
        <van-tab :title="'待评分'" name="s1"></van-tab>
        <van-tab :title="'待作废'" name="s2"></van-tab>
        <van-tab :title="'已提交'" name="s5"></van-tab>
        <van-tab :title="'已评分'" name="s6"></van-tab>
        <van-tab :title="'已撤回'" name="s3"></van-tab>
        <van-tab :title="'已作废'" name="s4"></van-tab>
      </van-tabs>

      <van-pull-refresh v-model="isLoadingAwait" @refresh="onRefreshAwait">
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          :finished-text="$t('module.noMore')"
          @load="onLoadAwait"
        >
          <div
            class="zb_group"
            v-for="(item, index) in assessList"
            :key="index"
          >
            <div class="zb_group">
              <van-cell
                class="ass_cell"
                size="large"
                :title="item.taskname"
                :label="`${item.empname}-${item.deptname}`"
                @click="goToApply(item)"
              >
                <template slot="label">
                  {{ item.empname }}-{{ item.deptname }}<br />
                  周期：{{ item.khtimename }}
                </template>
                <template>
                  <div style="color:red">权重：{{ item.taskper }}</div>
                  <div>{{ item.reqdate }}</div>
                  <div>
                    <van-button
                      v-if="activeName == 's1'"
                      type="info"
                      size="mini"
                      @click.stop="pf(item, '1')"
                      >评分</van-button
                    >
                    <van-button
                      v-if="activeName == 's1'"
                      type="danger"
                      size="mini"
                      @click.stop="pf(item, '2')"
                      >撤回</van-button
                    >

                    <van-button
                      v-if="
                        activeName == 's0' ||
                          activeName == 's1' ||
                          activeName == 's3'
                      "
                      type="primary"
                      size="mini"
                      @click.stop="pf(item, '3')"
                      >评论</van-button
                    >
                    <van-button
                      v-if="
                        activeName == 's0' ||
                          activeName == 's1' ||
                          activeName == 's3'
                      "
                      type="primary"
                      size="mini"
                      @click.stop="pf(item, '4')"
                      >汇报</van-button
                    >
                    <van-button
                      v-if="activeName == 's0' || activeName == 's3'"
                      type="warning"
                      size="mini"
                      @click.stop="pf(item, '5')"
                      >催办</van-button
                    >
                    <van-button
                      v-if="activeName == 's0' || activeName == 's3'"
                      type="info"
                      size="mini"
                      @click.stop="pf(item, '6')"
                      >提交</van-button
                    >
                    <van-button
                      v-if="activeName == 's2'"
                      type="danger"
                      size="mini"
                      @click.stop="pf(item, '7')"
                      >同意撤回</van-button
                    >
                    <van-button
                      v-if="activeName == 's1'"
                      type="warning"
                      size="mini"
                      @click.stop="pf(item, '8')"
                      >作废</van-button
                    >
                  </div>
                </template>
              </van-cell>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-dialog
      v-model="showDia"
      :title="'评分'"
      show-cancel-button
      :before-close="closeDia"
    >
      <div style="padding: 10px">
        <van-cell-group inset>
          <van-field
            v-model="pftype"
            label="评分方式"
            placeholder=""
            readonly
          />
          <van-field v-model="taskper" label="权重" placeholder="" readonly />
          <van-field
            v-model="pfbz"
            label="评分标准"
            placeholder=""
            readonly
            rows="3"
            type="textarea"
          />
          <van-field
            v-model="zpdesp"
            label="员工自评"
            placeholder=""
            readonly
            rows="3"
            type="textarea"
          />

          <van-field
            v-model="sxpf"
            type="number"
            label="时效评分"
            :required="pftype == '定量评分'"
            :readonly="pftype == '直接评分'"
            :disabled="pftype == '直接评分'"
            placeholder="请输时效评分"
          />
          <van-field
            v-model="zlpf"
            type="number"
            label="质量评分"
            :required="pftype == '定量评分'"
            :readonly="pftype == '直接评分'"
            :disabled="pftype == '直接评分'"
            placeholder="请输质量评分"
          />
          <van-field
            v-model="xjdf"
            type="number"
            :required="pftype == '直接评分'"
            :readonly="pftype == '定量评分'"
            :disabled="pftype == '定量评分'"
            label="最终得分"
            placeholder
          />
          <van-field
            v-model="yspy"
            required
            label="验收评语"
            rows="3"
            type="textarea"
            placeholder="请输验收评语"
          />
        </van-cell-group>
      </div>
    </van-dialog>
    <van-dialog
      v-model="showDia2"
      :title="ptype == '2' ? '撤回' : ptype == '7' ? '同意作废' : '作废'"
      show-cancel-button
      :before-close="closeDia2"
    >
      <div style="padding: 10px" v-if="ptype != '7'">
        <van-cell
          class="desc_cell"
          :value="ptype == '2' ? '撤回原因' : '作废原因'"
        />
        <van-field
          class="desc_field"
          v-model="checkmemo"
          rows="3"
          autosize
          type="textarea"
          :placeholder="ptype == '2' ? '撤回原因' : '作废原因'"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { add } from '@/utils//cal'
import {
  getDdForPostGsTaskList,
  getForPjGsTaskList,
  getForCancelGsTaskList,
  getHasReturnGsTaskList,
  getHasCancelGsTaskList,
  postGsTaskPj,
  returnGsTaskForDb,
  cancelDbGsTask,
  sureCancelGsTask,
  getGsPjScoreExitValue,
  getHasPostGsTaskList,
  getHasPjGsTaskList
} from '@api/wxjx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      activeName: 's0',
      ptype: '',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      sureList: [],
      checkList: [],
      noList: [],
      searchVal: '',
      sureNum: '',
      noNum: '',
      checkNum: '',
      showDia: false,
      showDia2: false,
      checkmemo: '',
      yspy: '',
      autoid: null,
      pftype: '',
      taskper: '',
      pfbz: '',
      zpdesp: '',
      sxpf: null,
      zlpf: null,
      xjdf: null
    }
  },
  watch: {
    searchVal(n) {
      this.onSearch()
    },
    sxpf(n) {
      this.calxjdf()
    },
    zlpf(n) {
      this.calxjdf()
    }
  },
  created() {},
  methods: {
    calxjdf() {
      if (this.sxpf !== null && this.zlpf !== null) {
        // 改成数据带出y
        getGsPjScoreExitValue({
          autoid: this.autoid,
          sxpf: this.sxpf || 0,
          zlpf: this.zlpf || 0,
          username: this.userInfo.username
        }).then(res => {
          this.xjdf = res.data[0].xjdf
        })
      }
    },
    onSearch() {
      if (this.searchVal) {
        this.assessList = this.assessList.filter(
          item =>
            item.empname.indexOf(this.searchVal) > -1 ||
            item.deptname.indexOf(this.searchVal) > -1 ||
            item.taskname.indexOf(this.searchVal) > -1
        )
      } else {
        this.getList()
      }
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    // 9.1.获取待审批的360考核方案列表
    getList() {
      let _this = this
      _this.finishedAwait = []
      if (this.activeName == 's0') {
        getDdForPostGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      } else if (this.activeName == 's1') {
        getForPjGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      } else if (this.activeName == 's2') {
        getForCancelGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      } else if (this.activeName == 's3') {
        getHasReturnGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      } else if (this.activeName == 's4') {
        getHasCancelGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      } else if (this.activeName == 's5') {
        getHasPostGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      } else if (this.activeName == 's6') {
        getHasPjGsTaskList({ empid: this.userInfo.empid }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.assessList = res.data
            _this.loadingAwait = false
            _this.finishedAwait = true
          }
        })
      }
    },
    onLoadAwait() {
      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      this.getList()
    },
    onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct(name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goToApply(item) {
      if (this.activeName == 's0') {
        this.$router.push({
          path: '/evaTaskApply',
          query: {
            title: item.taskname,
            isSure: true,
            autoid: item.autoid,
            from: '/evaTask',
            an: this.activeName
          }
        })
      } else if (this.activeName == 's3') {
        this.$router.push({
          path: '/ordTask',
          query: {
            type: '2',
            title: item.taskname,
            autoid: item.autoid,
            from: '/evaTask'
          }
        })
      } else {
        this.$router.push({
          path: '/queTaskApply',
          query: {
            title: item.taskname,
            autoid: item.autoid,
            from: '/evaTask'
          }
        })
      }
    },
    // 发起指标申请
    pf(item, type) {
      this.ptype = type
      this.autoid = item.autoid
      this.checkmemo = ''
      this.pftype = item.pftype
      this.taskper = item.taskper
      this.pfbz = item.pfbz
      this.zpdesp = item.zpdesp
      this.yspy = ''
      this.sxpf = null
      this.zlpf = null
      this.xjdf = null
      switch (type) {
        case '1':
          this.showDia = true
          break
        case '2':
          this.showDia2 = true
          break
        case '3':
          this.$router.push({
            path: '/pfTask',
            query: {
              title: item.taskname,
              autoid: item.autoid,
              type: type,
              from: '/evaTask'
            }
          })
          break
        case '4':
          this.$router.push({
            path: '/pfTask',
            query: {
              title: item.taskname,
              autoid: item.autoid,
              type: type,
              from: '/evaTask',
              ld: 1
            }
          })
          break
        case '5':
          this.$router.push({
            path: '/pfTask',
            query: {
              title: item.taskname,
              autoid: item.autoid,
              type: type,
              from: '/evaTask'
            }
          })
          break
        case '6':
          this.$router.push({
            path: '/evaTaskApply',
            query: {
              title: item.taskname,
              isSure: true,
              autoid: item.autoid,
              from: '/evaTask'
            }
          })
          break
        case '7':
          this.showDia2 = true
          break
        case '8':
          this.showDia2 = true
          break
        default:
          break
      }
    },
    // 弹窗关闭回调
    closeDia(action, done) {
      if (action == 'confirm') {
        if (this.yspy && this.xjdf) {
          this.postGsTaskPj()
          done()
        } else {
          Toast.fail('验收评语和分数不能为空')
          done(false)
        }
      } else {
        done()
      }
    },
    // 弹窗关闭回调
    closeDia2(action, done) {
      if (action == 'confirm') {
        if (this.ptype == '7') {
          this.sureCancelGsTask()
          done()
        } else if (this.checkmemo) {
          if (this.ptype == '2') {
            this.returnGsTaskForDb()
          } else {
            this.cancelDbGsTask()
          }
          done()
        } else {
          if (this.ptype == '2') {
            Toast.fail('撤回原因不能为空')
          } else {
            Toast.fail('作废原因不能为空')
          }
          done(false)
        }
      } else {
        done()
      }
    },
    // 提交任务评分接口
    postGsTaskPj() {
      postGsTaskPj({
        autoid: this.autoid,
        pftype: this.pftype,
        username: this.userInfo.username,
        yspy: this.yspy,
        sxpf: this.sxpf ? parseFloat(this.sxpf) : 0,
        zlpf: this.zlpf ? parseFloat(this.zlpf) : 0,
        xjdf: parseFloat(this.xjdf)
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.submitScu'))
            this.getList()
          }
        }
      })
    },
    // 撤回任务评分接口
    returnGsTaskForDb() {
      returnGsTaskForDb({
        autoid: this.autoid,
        checkmemo: this.checkmemo,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.submitScu'))
            this.getList()
          }
        }
      })
    },
    // 作废任务接口
    cancelDbGsTask() {
      cancelDbGsTask({
        autoid: this.autoid,
        checkmemo: this.checkmemo,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.submitScu'))
            this.getList()
          }
        }
      })
    },
    // 同意作废任务接口
    sureCancelGsTask() {
      sureCancelGsTask({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.submitScu'))
            this.getList()
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
    }
    .KPITagRefresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 220px);
    }

    .zb_group {
      .ass_cell {
        margin-bottom: 10px;
        .van-cell__title {
          flex: 1;
          line-height: 54px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #2b8df0;
        }
        .van-cell__label {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .van-cell__value {
          position: relative;
          color: #333333;
          padding-bottom: 60px;
          .task_ischeck {
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .custom-score {
            font-size: 48px;
            font-family: DIN;
            font-weight: bold;
            color: #2b8df0;
          }
        }
      }
    }
  }
}
</style>
